import $ from 'jquery';

/**
 * AccountNav.js
 * This class is responsible for the handling navigation in the account page
 */

class AccountNav {
  constructor() {
    this.accountNavItems = document.querySelectorAll('a[data-block-id]');

    if (this.accountNavItems) {
      this.initAccountPageNav();
    }
  }

  /**
   * This function is responsible for handling the navigation in the account page
   */
  initAccountPageNav() {
    const that = this;

    $('a[data-block-id]').on('click', function(e) {
      e.preventDefault();
      const blockId = $(this).data('block-id');
      const block = document.querySelector('section.' + blockId);
      
      if (block) {
        // Close other active blocks
        that.closeOtherActiveBlocks(blockId);

        $(this).addClass('active');
        $(block).slideDown();
      }
    });

    // Activate active menu item
    const activeMenuItem = $('a[data-block-id].active');

    if (activeMenuItem) {
      const activeBlock = document.querySelector('section.' + $(activeMenuItem).data('block-id'));
      $(activeBlock).slideDown();
    }
  }

  /**
   * This function is responsible for closing other active blocks
   * @param {string} blockId
   */
  closeOtherActiveBlocks(blockId) {
    const that = this;

    that.accountNavItems.forEach(function(item) {
      if ($(item).data('block-id') !== blockId) {
        item.classList.remove('active');
        const block = document.querySelector('section.' + $(item).data('block-id'));

        $(block).slideUp();
      }
    });
  }
}

new AccountNav();
