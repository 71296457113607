import $ from 'jquery';
const _ = require('lodash');

$(function () {
  updateMargin();

  $(window).on('resize', function () {
    updateMargin();
  });
  setupSocialLinkClick();
  setupContactClick();
  setupNewsletterSubmit();
  setupProductClick();
  setupLogin();
  setupRegister();
});

function updateMargin() {
  let header = $('.site-header').height();
  $('body').css('margin-top', header + 'px');
}

const checkPostal = (postal, country) => {
  const data = {
    postal,
    country,
  };

  const result = $.ajax({
    type: 'GET',
    url: `/check-postal`,
    data,
    async: false,
  });

  return result;
};

// Validate postal code when postal code field is changed
const billingPostalField = $(
  '.checkout-standard-address-billing .form-item.postal input'
);

if (billingPostalField.length) {
  const deliveryAddressIsSameAsBilling = $(
    '.checkout-standard-address-delivery input#ca_deliveryoption-like'
  );
  const deliveryPostalField = $(
    '.checkout-standard-address-delivery .form-item.postal input'
  );
  // If deliveryAddressIsSameAsBilling is changed
  deliveryAddressIsSameAsBilling.on('change', () => {
    if (deliveryAddressIsSameAsBilling.is(':checked')) {
      checkAirmeeDelivery(billingPostalField.val());
    } else {
      checkAirmeeDelivery(deliveryPostalField.val());
    }

    deliveryPostalField.each(function () {
      deliveryPostalField.off('keyup');
      // When postal code field is changed
      deliveryPostalField.on(
        'keyup',
        _.debounce(() => {
          if (!deliveryAddressIsSameAsBilling.is(':checked')) {
            checkAirmeeDelivery(deliveryPostalField.val());
          }
        }, 1000)
      );
    });
  });

  // Check each postal code field change
  billingPostalField.each(function () {
    $(this).off('keyup');
    // When postal code field is changed
    $(this).on(
      'keyup',
      _.debounce(() => {
        if (deliveryAddressIsSameAsBilling.is(':checked')) {
          checkAirmeeDelivery($(this).val());
        }
      }, 1000)
    );
  });
}

/**
 * This function checks if the postal code is valid for airmee delivery
 */
async function checkAirmeeDelivery(postalFieldValue) {
  const airmeeDelivery = $(
    '.checkout-standard-delivery .item.item-service.d-airmee'
  );
  const DHLDelivery = $(
    '.checkout-standard-delivery .item.item-service.d-unifaunweb input:radio'
  );

  if (!_.isEmpty(postalFieldValue)) {
    // check deliveryAddressIsSameAsBilling is true
    const valid = checkPostal(postalFieldValue, 'SE');

    return await valid.then((res) => {
      // If postal code is valid for airmee delivery
      if (res.success && res.data.length > 0) {
        airmeeDelivery.show();
        return true;
      }

      // If postal code is not valid for airmee delivery
      airmeeDelivery.hide();
      DHLDelivery[0].checked = true;
      return false;
    });
  } else {
    airmeeDelivery.hide();
    DHLDelivery[0].checked = true;
  }
}

/**
 * Delivery address auto hide/show function
 *
 */
const sameDeliveryAddressCheck = $('#ca_deliveryoption-like');
const diffDeliveryAddressCheck = $('#ca_deliveryoption-null');
const newDeliveryAddressFormList = $('#delivery-address .form-list');
const savedDeliveryAddressFormList = $('#saved-address-block .form-list');
const savedDeliveryAddressCheck = $('.saved-delivery:first');

sameDeliveryAddressCheck.on('change', function () {
  if ($(this).is(':checked')) {
    console.log('checked');
    // check for saved delivery address
    if (
      savedDeliveryAddressCheck.length == 1 &&
      savedDeliveryAddressCheck.is(':checked')
    ) {
      console.log('saved');

      savedDeliveryAddressCheck.trigger('click');
      savedDeliveryAddressFormList.hide(200);
    } else {
      console.log('not saved');

      // uncheck new address
      diffDeliveryAddressCheck.prop('checked', false); // uncheck the different delivery address field
      newDeliveryAddressFormList.hide(200); // hide the delivery address fields
    }
  } else {
    console.log('unchecked');
    if (
      savedDeliveryAddressCheck.length == 1 &&
      !savedDeliveryAddressCheck.is(':checked')
    ) {
      console.log('saved');
      // check saved delivery address
      savedDeliveryAddressCheck.trigger('click');
      savedDeliveryAddressFormList.show(200); // show the delivery address form fields
    } else {
      console.log('not saved');
      // check new address
      diffDeliveryAddressCheck.prop('checked', true); // check the different delivery address field
      newDeliveryAddressFormList.show(200); // show the delivery address form fields
    }
  }
});

function setupSocialLinkClick() {
  $('.site-footer__nav-list--social .site-footer__nav-item').on(
    'click',
    '.site-footer__nav-item-link',
    function (ev) {
      ev.preventDefault();
      const url = $(this).attr('href');
      const socialNetwork = $(this).attr('title');
      socialIconClick(socialNetwork);
      window.open(url, '_blank');
      return false;
    }
  );
}

function setupContactClick() {
  $('.site-footer__row-item--address').on(
    'click',
    '.site-footer__contact-link',
    function (ev) {
      ev.preventDefault();
      const url = $(this).attr('href');
      const contactType = $(this).attr('title');
      onContact(contactType);
      window.open(url, '_self');
      return false;
    }
  );
}

function setupNewsletterSubmit() {
  $('.site-footer__newsletter-btn').on('click', function (ev) {
    ev.preventDefault();
    newsletterSignup();
    $('#mc-embedded-subscribe-form').trigger('submit');
    return false;
  });
  $('.newsletter-block__newsletter-btn').on('click', function (ev) {
    ev.preventDefault();
    newsletterSignup();
    $('#block-embedded-subscribe-form').trigger('submit');
    return false;
  });
}

function setupProductClick() {
  $('.prodItem-link').on('click', function (ev) {
    ev.preventDefault();
    const url = $(this).attr('href');
    const productObj = $(this)
      .parents('.product-item')
      .next('.prodItem-json')
      .val();
    productClick(productObj, 'actionField');
    window.open(url, '_self');
    return false;
  });
}

function setupLogin() {
  $('#login-btn').on('click', function (ev) {
    ev.preventDefault();
    onLogin();
    $('#login-form').trigger('submit');
    return false;
  });
}

function setupRegister() {
  $('#register-btn').on('click', function (ev) {
    ev.preventDefault();
    onRegister();
    $('#signup-form').trigger('submit');
    return false;
  });
}
