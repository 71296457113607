import $ from 'jquery';

$(document).ready(function () {
  // Show product list if subcategory is active
  const categoryList = document.querySelector('.js-subcategory-list');
  const activeSubcategory = categoryList ? document.querySelector('.js-subcategory-list .top-item.active .submenu .cat-item.active') : false;

  if (activeSubcategory) {
    categoryList.classList.add('subcategory-active');
    $(categoryList).find('.aimeos.catalog-filter').hide();

    if ($(categoryList).find('.catalog-list--supplier')) {
      $(categoryList).find('.catalog-list--supplier').show();
    }
  }

  // Foldout for the category menu that reveals and hides the subcategory menu
  if ($('.js-category-menu .js-subcategory-opener')) {
    $('.js-category-menu .js-subcategory-opener').on('click', function () {
      const submenu = $(this).parents('.top-item.has-submenu').find('.submenu');
      // Check other submenus
      const otherSubmenus = $('.js-category-menu .top-item.has-submenu .submenu');
      otherSubmenus.each(function () {
        if ($(this).is(':visible') && !$(this).is(submenu)) {
          $(this).slideUp();
        }
      });

      // Check if submenu is visible and hide it
      if (submenu.is(':visible')) {
        submenu.slideUp();
        $(this).removeClass('active');
      } else {
        // Show submenu
        submenu.slideDown();
        $(this).addClass('active');
      }
    });
  }

  // Check stock out product items
  $('.catalog-list-items .list-items .product-item').each(function () {
    const productItem = $(this);
    const stock = productItem.find('.stockitem');

    if ($(stock).hasClass('stock-out')) {
      if ($(productItem).find('form.basket')) {
        $(productItem).find('form.basket')
        $(productItem).find('form.basket').hide();
      }
    }
  });

  /**
   * If is checkout page 
   * Preselect language (SV) and country (SE) field on checkout page
   */
  if ($('.checkout-standard').length) {
    // For Country field
    $('.form-item.countryid').each(function() {
      $(this).find('select').val('SE');

      // Hide country field
      $(this).hide();
    });

    // For Language field
    $('.form-item.languageid').each(function() {
      $(this).find('select').val('se');

      // Hide language field
      $(this).hide();
    });
  }
});