import Cookies from "js-cookie";
import $ from "jquery";

class Cookiebar {
  constructor() {
    this.cookieName = encodeURIComponent("cookie_acceptance"); 
    this.cookieValue = 1;
    this.cookiebar = document.getElementById("cookiebar");
    this.cookieAcceptButton = cookiebar.querySelector(".js-cookie-accept");
    this.cookieDenyButton = cookiebar.querySelector(".js-cookie-deny");
    this.cookieRevealButton = cookiebar.querySelector(".js-cookie-reveal");

    if (this.cookiebar && this.cookieAcceptButton) {
      this.cookieCheckups();
    }
  }

  cookieCheckups() {
    let that = this;

    if (Cookies.get(that.cookieName)) {
    } else {
      that.cookieAcceptButton.onclick = () => {
        Cookies.set(that.cookieName, that.cookieValue, {
          expires: 365,
          path: "/",
        });

        if (Cookies.get(that.cookieName)) {
          that.cookiebar.classList.remove("is-visible");
        }
      };
      that.cookiebar.classList.add("is-visible");
    }

    // Cookie Deny Button
    if (that.cookieDenyButton) {
      that.cookieDenyButton.onclick = () => {
        that.cookiebar.classList.remove("is-visible");
      };
    }

    // Cookie Reveal Button
    if (that.cookieRevealButton) {
      that.cookieRevealButton.onclick = () => {
        $(that.cookieRevealButton.nextElementSibling).slideToggle();
      };
    }
  }
}

new Cookiebar();
