/**
 * Header class
 * This class is responsible for Nav Opener and searchbar opener in top header
 */

class Header {
  constructor() {
    this.body = document.querySelector('body');
    this.navOpener = document.querySelector('.js-toggle-nav');
    this.searchbarOpener = document.querySelector('.js-toggle-searchbar');
    this.basketOpener = document.querySelector('.js-button-cart');

    if (this.navOpener) {
      this.initNavOpener();
    }

    if (this.searchbarOpener) {
      this.initSearchbarOpener();
    }

    if (this.basketOpener) {
      this.initBasketOpener();
    }
  }

  // Inits Nav Opener
  initNavOpener() {
    const that = this;
    if (that.navOpener) {
      that.navOpener.addEventListener('click', () => {
        // Check if searchbar is active
        that.body.classList.contains('js-searchbar-active') ? that.body.classList.remove('js-searchbar-active') : false;
        that.body.classList.toggle('js-nav-active');
      });
    }
  }

  // Inits Searchbar Opener
  initSearchbarOpener() {
    const that = this;
    if (that.searchbarOpener) {
      that.searchbarOpener.addEventListener('click', () => {
        // Check if nav is active 
        that.body.classList.contains('js-nav-active') ? that.body.classList.remove('js-nav-active') : false;
        that.body.classList.toggle('js-searchbar-active');
        const searchbar = document.querySelector('input[name="f_search"]');

        setTimeout(() => {
          if (searchbar) {
            searchbar.focus();
          }
        }, 100);
    
        // Close searchbar if clicked outside
        const searchbarOverlay = document.querySelector('.js-searchbar .searchbar__overlay');

        if (searchbarOverlay) {
          searchbarOverlay.addEventListener('click', () => {
            that.body.classList.remove('js-searchbar-active');
            that.searchbarOpener.focus();
          });
        }
      });
    }
  }

  // Inits Basket Opener
  initBasketOpener() {
    const that = this;
    if (that.basketOpener) {
      that.basketOpener.addEventListener('click', () => {
        that.body.classList.contains('js-basket-active') ? false : that.body.className = '';
        that.body.classList.toggle('js-basket-active');

        // Close basket if clicked outside
        const basketOverlay = document.querySelector('span.basket-mini__overlay');
        if (basketOverlay) {
          basketOverlay.addEventListener('click', () => {
            that.body.classList.remove('js-basket-active');
          });
        }

        // Close basket if basket closer is clicked
        const basketCloser = document.querySelector('.basket-mini__closer');
        if (basketCloser) {
          basketCloser.addEventListener('click', () => {
            that.body.classList.remove('js-basket-active');
          });
        }
      });
    }
  }
}

new Header();