// Scroll to filter checkbox on click when page is reloaded
import $ from "jquery";

const selectedFilter = document.querySelector(
    '.product-filter__filter li.attr-item input[type="checkbox"][checked]'
);
const filterToggleBtn = $(".js-toggle-filter");
const filterBlock = $(".product-filter__inner");
const productList = $(".product-list");
const productListHeight = productList ? productList.height() : 0;
const searchParam = new window.URLSearchParams(window.location.search);
const selectedFilterElement = selectedFilter ?? $(".product-filter__inner");
const pathName = window.location.pathname;

if (filterToggleBtn) {
    // filterToggleBtn on click
    filterToggleBtn.on("click", function () {
        filterBlock.slideToggle(400);
        if (pathName !== "/") {
            productList.slideToggle(400);
        }

        // Scroll to selected filter
        if (productList.is(":hidden")) {
            $("html,body").animate(
                {
                    scrollTop: $(window).scrollTop() - productListHeight,
                },
                "200"
            );
        } else {
            $("html,body").animate(
                {
                    scrollTop: $(window).scrollTop() - 200,
                },
                "200"
            );
        }
    });
}

// If filter is selected or is searchpage show products listing in default
if (selectedFilter || searchParam.get("f_search")) {
    $(".product-filter__inner").show(0);
}
if (pathName !== "/") {
    if (selectedFilter || searchParam.get("f_search")) {
        productList.hide(0);
        removeFilterParamsFromCategotyLink();
        window.scrollTo(0, selectedFilterElement.offsetTop - 180);
    }

    function removeFilterParamsFromCategotyLink() {
        productList.find("a").each(function () {
            const href = $(this).attr("href");
            const newHref = href.replace(/\?.*$/, "");
            $(this).attr("href", newHref);
        });
    }
}
