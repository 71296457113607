import $ from 'jquery';

/**
 * PrebookingForm.js
 * This class is resposible for handling prebooking form in the product
 */

class PrebookingForm {
  constructor() {
    this.prebookingForm = document.querySelector('.js-prebooking-form');
    this.formData;

    if (this.prebookingForm) {
      this.init();
    }
  }

  // Initliazes the prebooking form
  init() {
    const that = this;
    const prebookingFormSubmitBtn = this.prebookingForm ? this.prebookingForm.querySelector('.js-prebooking-submit') : false;

    if (prebookingFormSubmitBtn) {
      this.prebookingForm.onsubmit = (e) => {
        e.preventDefault();
        const formFields = this.prebookingForm.querySelectorAll('input, textarea');
        const mailToURL = prebookingFormSubmitBtn.getAttribute('href');

        let subject, body;

        let subjectInput = that.prebookingForm.querySelector('input[name="subject"]');
        subject = subjectInput ? subjectInput.value : '';

        let formEmailInput = that.prebookingForm.querySelector('input[name="email"]');
        let formEmail = formEmailInput ? formEmailInput.value : '';
        let formBodyInput = that.prebookingForm.querySelector('textarea[name="body"]');
        let formBody = formBodyInput ? formBodyInput.value : '';

        body = encodeURIComponent(`Email: ${formEmail}\r\nMessage: ${formBody}`);

        let url = `${mailToURL}?subject=${subject}&body=${body}`;

        prebookingFormSubmitBtn.setAttribute('href', url);
        prebookingFormSubmitBtn.click();
        setTimeout(() => {
          $('.aimeos-overlay').remove();
        }, 18);


        // Hide form and show thank you message
        setTimeout(() => {
          $(that.prebookingForm).slideUp();
          $(that.prebookingForm).next().slideDown();
        }, 2200);
      }
    }
  }
}

export default new PrebookingForm();
