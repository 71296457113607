/**
 * Aimeos CMSContent.js
 * This file handles the content of the CMS added from the Aimeos CMS admin interface
 * Or in other words fixes the broken content added from the Aimeos CMS admin 
 */

class CMSContent {
  constructor() {
    this.CMSContent = document.querySelectorAll('.cms-content');

    if (this.CMSContent.length > 0) {
      this.initCMSContent();
    }
  }

  /**
   * Initialize the CMS content if it exists in current page
   */
  initCMSContent() {
    const that = this;
    this.CMSContent.forEach(content => {
      // These datas are only added from the Aimeos CMS admin

      // Handles elements with background this element is 
      that.handleBgElements(content.querySelectorAll('[data-background]'));
      // Handles buttons and links added from the CMS admin
      that.handleCMSButtonsAndLinks(content.querySelectorAll('.btn, a.space'));
    });
  }

  /**
   * Handles the background elements if they exist in the cms content
   * @param {HTMLElement} bgElements 
   */
  handleBgElements(bgElements) {
    const that = this;
    if (bgElements.length > 0) {
      // When js is loaded
      window.addEventListener('DOMContentLoaded', (event) => {
        setTimeout(() => {
          bgElements.forEach(bgElement => {
            const background = bgElement.hasAttribute('data-background') ? bgElement.getAttribute('data-background').replace(/ .*/,'') : false;
            bgElement.style.backgroundImage = background ? `url(${background})` : false;
          });
        }, 100);
      });
    }
  }

  /**
   * Handles the buttons and links added from the CMS admin
   * Adds the title from its attribute to the button and link
   * @param {HTMLElement} buttonsAndLinks 
   */
   handleCMSButtonsAndLinks(buttonsAndLinks) {
    const that = this;
    if (buttonsAndLinks.length > 0) {
      buttonsAndLinks.forEach(element => {
        const title = element.getAttribute('title');
        element.innerText = title;
      });
    }
  }
}

new CMSContent();
